import $ from 'jquery'
import Slick from 'slick-carousel';

import gsap from 'gsap'

function init() {
    $('.gallery__images__carousel').each((i, el) => {
        var $curCarousel = $(el);
        $curCarousel
            .on('init', function (event, slick) {
                setTotalCount($curCarousel, slick);
                updateCounter(event, slick, 0);
                showCaption($curCarousel, slick, 0);
            })
            .slick({
                speed: 350,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            speed: 500
                        }
                    }
                ]
            })
            .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                updateCounter(event, slick, nextSlide);
                showCaption($curCarousel, slick, nextSlide);
            })
    });
}

function setTotalCount($carousel, slick) {
    $carousel.parent().find('.gallery__images__counter__total').html('/' + (slick.slideCount < 10 ? '0' : '') + (slick.slideCount));
}

function updateCounter(event, slick, nextSlide) {
    let $curField = $(event.currentTarget).parent().find('.gallery__images__counter__current');
    $curField.html('');     // setting to empty helps fix safari redraw glitch
    $curField.html((slick.currentSlide < 10 ? '0' : '') + (nextSlide + 1));
}

function showCaption($caption, slick, nextSlide) {
    $caption.parent().parent().find('.gallery__caption').each((i, el) => {
        var $curCaption = $(el).find('li');
        gsap.killTweensOf($curCaption);
        $curCaption.hide();
        gsap.fromTo($($curCaption.get(nextSlide)), 0.6, {display: 'none', xPercent: 0, opacity: 0}, {
            opacity: 1,
            display: 'block',
            xPercent: 0,
            force3D: true
        })
    });
}

export default {init}
